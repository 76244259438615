import React from "react";
import { graphql } from "gatsby";
import { sanitize } from "isomorphic-dompurify";
import { Remarkable } from "remarkable";

export const fragment = graphql`
    fragment microMarkdown on ContentfulMicroMarkdown {
        markdown {
            markdown
        }
    }
`;

// The main component
const MicroMarkdown = (props) => {
    // Markdown parser
    const md = new Remarkable({
        html: true, // Enable HTML tags in source
        xhtmlOut: true, // Use '/' to close single tags (<br />)
    });

    // The content to place in the DOM
    let markdownContent = props.item ? md.render(props.item.markdown.markdown) : "";

    // Utilize DOMPurify to help reduce danger of dangerouslySetInnerHTML
    markdownContent = sanitize(markdownContent);

    return <div dangerouslySetInnerHTML={{ __html: markdownContent }} />;
};

export default MicroMarkdown;
